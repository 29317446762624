/* eslint-disable no-unused-vars */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Chat from "@material-ui/icons/Chat";
import Build from "@material-ui/icons/Build";

// core components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import NavPills from "components/NavPills/NavPills.js";
import ScrollableAnchor from 'react-scrollable-anchor';
import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Paper from '@material-ui/core/Paper';
import InfoArea from "components/InfoArea/InfoArea.js";
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

const useStyles = makeStyles(styles);

export default function WhatForSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>¿Cómo funciona NEOS?</h2>
      <ScrollableAnchor id={'whatfor'}>
      <h5 className={classes.description}>
        Hemos diseñado una serie de preguntas clave, que buscan evaluar y diagnosticar el estado de cada uno de los aspectos a tener en cuenta a la hora de formular estrategias de gestión del conocimiento, a saber, negocio, operación y seguimiento:
      </h5>
      </ScrollableAnchor>
      
      <NavPills
        alignCenter={true}
        color="success"
        tabs={[
          {
            tabButton: "Negocio",
            tabContent: (
              <span className={classes.textLeft}>
                <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <Paper style={{height: "14rem"}} elevation={0}>
                  <p style={{ textAlign: 'center', fontSize: '17px' }}>Facilitamos el análisis de la gestión del conocimiento partiendo de la base de si es una parte importante de la estrategia y se encuentra alineada con los retos de la organización además de si se tienen bien definidos los dominios de conocimiento críticos.</p>
                </Paper>  
                </GridItem>
                </GridContainer>
              </span>
            )
          },
          {
            tabButton: "Operación",
            tabContent: (
              <span className={classes.textLeft}>
                <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <Paper style={{height: "14rem"}} elevation={0}>
                    <p style={{ textAlign: 'center', fontSize: '17px' }}>Procesos de conocimiento: evaluaremos el estado de los procesos o dinámicas de gestión de conocimiento relacionados con sistematizar/ordenar, conectar/adquirir, desarrollar/renovar y socializar/transferir conocimiento.</p>
                  </Paper>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                <Paper style={{height: "14rem"}} elevation={0}>
                  <p style={{ textAlign: 'center', fontSize: '17px' }}>Reguladores: analizaremos el nivel de madurez y/o la existencia de políticas, roles, etc. que impulsen, fomenten  y regulen la gestión del conocimiento.</p>
                </Paper>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                <Paper style={{height: "14rem"}} elevation={0}>
                  <p style={{ textAlign: 'center', fontSize: '17px' }}>Habilitadores: evaluaremos la existencia y la utilidad de las herramientas, tecnologías y/o espacios donde se facilita el intercambio de conocimiento y la colaboración.</p>
                </Paper>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                <Paper style={{height: "14rem"}} elevation={0}>
                  <p style={{ textAlign: 'center', fontSize: '17px' }}>Dinamizadores: analizaremos de qué forma se estimula la participación de las personas a la hora de compartir/transferir su conocimiento.</p>
                </Paper>
                </GridItem>
                </GridContainer>
              </span>
            )
          },
          {
            tabButton: "Seguimiento",
            tabContent: (
              <span className={classes.textLeft}>
                <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Paper style={{height: "14rem"}} elevation={0}>
                    <p style={{ textAlign: 'center', fontSize: '17px' }}>Evaluaremos la existencia de métricas e indicadores que permitan realizar el seguimiento de las actividades de gestión del conocimiento.</p>
                  </Paper>
                </GridItem>
                </GridContainer>
              </span>
            )
          }
        ]}
      />
      </div>
    
  );
}
/* eslint-disable no-unused-vars */