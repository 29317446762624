/* eslint-disable no-unused-vars */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import MapIcon from '@material-ui/icons/Map';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SpaRoundedIcon from '@material-ui/icons/SpaRounded';
import HomeWorkIcon from '@material-ui/icons/Business';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import ScrollableAnchor from 'react-scrollable-anchor';

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>neos</h2>
          <ScrollableAnchor id={'what'}>
          <h5 className={classes.description}>
            Saber de dónde partimos es básico para poder construir una estrategia de Gestión del Conocimiento exitosa. A través de nuestra herramienta de diagnóstico NEOS, responderás a una serie de preguntas clave que hemos diseñado y que apuntan a diferentes aspectos de la estrategia de Gestión del Conocimiento.
          </h5>
          </ScrollableAnchor>             
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <h5 className={classes.description}>
            Con el resultado de NEOS podrás tener una representación visual del estado de madurez de la Gestión del Conocimiento de tu organización, así como la posibilidad de obtener una serie de recomendaciones.
          </h5>
          <h5 className={classes.description}>
          Son muchos los responsables de políticas y estrategias de Gestión del Conocimiento, o personas pertenecientes a las áreas de recursos humanos, innovación y/o calidad que podrán obtener a través de NEOS una visión útil del estado de la Gestión del Conocimiento con el objetivo de conseguir una imagen confiable a partir de la cual construir un plan de actuación robusto.
          </h5>                    
        </GridItem>
      </GridContainer>
      <div style={{ marginBottom: "35px" }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <InfoArea
              title="Gestión del Conocimiento"
              description=""
              icon={EmojiObjectsIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <InfoArea
              title="Diagnóstico"
              description=""
              icon={AssignmentTurnedInIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <InfoArea
              title="Interpretación de Resultados"
              description=""
              icon={InsertChartIcon}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <InfoArea
              title="Recomendaciones de Mejora"
              description=""
              icon={TrendingUpIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
/* eslint-disable no-unused-vars */