/* eslint-disable no-unused-vars */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ScrollableAnchor from 'react-scrollable-anchor';
import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";
import CardHeader from "components/Card/CardHeader.js";

import team1 from "assets/img/faces/avatar.jpg";
import team2 from "assets/img/faces/christian.jpg";
import team3 from "assets/img/faces/kendall.jpg";

const useStyles = makeStyles(styles);

export default function MethodSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <ScrollableAnchor id={'method'}><h2 className={classes.title}>Planes disponibles</h2></ScrollableAnchor>
      
      {/*<h5 className={classes.description}>
        La esencia de los ecosistemas de conocimiento e innovación se centra en la identificación de activos intangibles que no sólo residen en las competencias de las personas sino en aquéllos otros activos que potencian o dificultan su desarrollo, cuya naturaleza puede ser estructural o relacional.
        De esta forma, la metodología del KIT MAKE se asienta en los siguientes 4 elementos:
      </h5>*/}
      
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
          <Card style={{height: "24rem"}}>
            <CardHeader color="success"><span style={{ fontSize: 19, fontWeight: 'bold' }}>Versión gratuita</span></CardHeader>
              <CardBody>
                <p>
                  Accede a NEOS de forma gratuita. Podrás responder a las preguntas clave y como resultado de ello obtendrás una primera representación gráfica de los resultados obtenidos. ¿Deseas profundizar más? Pásate a nuestro plan Premium.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          <Card style={{height: "24rem"}}>
            <CardHeader color="success"><span style={{ fontSize: 19, fontWeight: 'bold' }}>Versión premium</span></CardHeader>
              <CardBody>
              <p>
                Pondremos a tu disposición una instancia de nuestra herramienta para utilizarla en tu organización. Varias personas accederán mediante invitación a NEOS, respondiendo a las preguntas clave. El resultado de ello será:<br />
                •	Representación visual de la media de todos los resultados obtenidos.<br />
                •	Posibilidad de que el administrador consulte el resumen de datos obtenidos.<br />
                •	Recibirás unas recomendaciones generales del equipo de ICA2 en base a los resultados obtenidos.
              </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          <Card style={{height: "24rem"}}>
          <CardHeader color="success"><span style={{ fontSize: 19, fontWeight: 'bold' }}>Versión premium + consultoría</span></CardHeader>
              <CardBody>
              <p>
                Además de todo lo incluido en el plan Premium, tu organización recibirá un servicio de consultoría personalizado basado en las respuestas obtenidas que pudiera detonar en proyectos de gestión del conocimiento y/o mejora en tu organización. Nos sentamos contigo para marcar de forma conjunta un camino de mejora.
              </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
      {/*<h5 className={classes.description}>
        El Kit permite realizar estos análisis y, en virtud de los resultados, aporta diferentes recomendaciones particulares y generales. El mero hecho de visualizar los componentes del Kit ya supone un enriquecimiento de la perspectiva de análisis de los agentes implicados con la estrategia u operativa de los ecosistemas de conocimiento e innovación. 
    </h5>*/}
    </div>
  );
}
/* eslint-disable no-unused-vars */