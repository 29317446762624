import React, { Component } from 'react';

export const KMPSiteContext = React.createContext();
export const KMPSiteConsumer = KMPSiteContext.Consumer

class KMPSiteProvider extends Component {

  state = {
    
  }
  
  render () {
    return (
      <KMPSiteContext.Provider value={this.state}>
        {this.props.children}
      </KMPSiteContext.Provider>
    )
  }
}
export default KMPSiteProvider;