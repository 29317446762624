/* eslint-disable no-unused-vars */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ScrollableAnchor from 'react-scrollable-anchor';
import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import team1 from "assets/img/faces/avatar.jpg";
import team2 from "assets/img/faces/christian.jpg";
import team3 from "assets/img/faces/kendall.jpg";

const useStyles = makeStyles(styles);

export default function ResultsSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Resultados esperados de NEOS</h2>
      <ScrollableAnchor id={'results'}>
      <h5 className={classes.description}>
        Con la aplicación NEOS se espera potenciar el trabajo de diagnóstico, asesoramiento y articulación de los ecosistemas de conocimiento e innovación facilitando resultados como:
      </h5>
      </ScrollableAnchor>
      <div>
        <p className={classes.textLeft}>
        •	Ofrecer un espacio a través del cual reflexionar sobre aspectos clave de la Gestión del Conocimiento en las organizaciones.<br />
        •	Realizar un análisis automático en base a todas las respuestas obtenidas.<br />
        •	Obtener de forma gráfica y muy visual una representación del estado de madurez (nivel alto, medio o bajo) de cada uno de los aspectos clave de la gestión del conocimiento, utilizando para ello nuestro KMPlanner.<br />
        •	En aquellos elementos en los que se ha obtenido una valoración media o baja, se ofrecerán una serie de recomendaciones generales de mejora.<br />
        •	Si se requiere, se ofrecerá la posibilidad de tener un espacio de consultoría personalizada en base a los resultados obtenidos.
        </p>
      </div>
    </div>
  );
}
/* eslint-disable no-unused-vars */